import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        jwt: '',
        user: '',
        today: '',
        layout: '',
        drawer: ''
    },
    getters: {
        jwt: state => state.jwt,
        user: state => state.user,
        today: state => state.today,
        layout: state => state.layout,
        drawer: state => state.drawer,
    },
    mutations: {
        jwt(state, payload) {
            state.jwt = payload
        },
        user(state, payload) {
            state.user = payload
        },
        today(state, payload) {
            state.today = payload
        },
        layout(state, payload) {
            state.layout = payload
        },
        jwtReset(state) {
            state.jwt = null
        },
        drawer(state, payload) {
            state.drawer = payload
        }
    },
    actions: {},
    modules: {}
})

export default store