<template>
  <component :is="nice">
    <router-view></router-view>
  </component>
</template>

<script>
import LayoutBlank from '@/pages/LayoutBlank.vue'
import LayoutMobile from '@/pages/LayoutMobile.vue'
import LayoutDesktop from '@/pages/LayoutDesktop.vue'

export default {
  name: 'App',

  components: {
    LayoutBlank,
    LayoutMobile,
    LayoutDesktop
  },

  data: () => ({

  }),

  setup() { 

  }, 

  computed: {
    nice() {
      console.log('nice', this.$store.state.layout)

      if (this.$store.state.layout == 'desktop') {
        return LayoutDesktop
      }
      else if (this.$store.state.layout == 'mobile') {
        return LayoutMobile
      }
      else {
        return LayoutBlank
      }
    }
  }
};
</script>

<style>
@import './assets/styles/common.css';
</style>
