export const routesUnauthenticated = [
    {
        path: '/',
        redirect: '/intro'
    },
    {
        path: '/intro',
        component: () => import('@/pages/layout-blank/IntroPage.vue'),
    },
    {
        path: '/login',
        component: () => import('@/pages/layout-blank/LoginPage.vue'),
    },
    {
        path: '/signup',
        component: () => import('@/pages/layout-blank/SignupPage.vue'),
    }    
];

export const routesMobileAuth = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/intro',
        component: () => import('@/pages/layout-blank/IntroPage.vue'),
    },
    {
        path: '/login',
        component: () => import('@/pages/layout-blank/LoginPage.vue'),
    },
    {
        path: '/home',
        component: () => import('@/pages/layout-mobile/MyParking.vue'),
    },
    {
        path: '/parking',
        component: () => import('@/pages/layout-mobile/MyParking.vue'),
    },
    {
        path: '/reservation',
        component: () => import('@/pages/layout-mobile/MakeReservation.vue')
    },
    {
        path: '/calendar',
        component: () => import('@/pages/layout-mobile/AvailabilityCalendar.vue')
    }
];

export const routesDesktopAuth = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/intro',
        component: () => import('@/pages/layout-blank/IntroPage.vue'),
    },
    {
        path: '/login',
        component: () => import('@/pages/layout-blank/LoginPage.vue'),
    },
    {
        path: '/home',
        component: () => import('@/pages/layout-desktop/AvailabilityCalendar.vue')
    },
    {
        path: '/reservation',
        component: () => import('@/pages/layout-desktop/ManageReservations.vue')
    },
    {
        path: '/account',
        component: () => import('@/pages/layout-desktop/ManageAccounts.vue')
    },
    {
        path: '/parking',
        component: () => import('@/pages/layout-desktop/ManageParkingLots.vue')
    },
    {
        path: '/calendar',
        component: () => import('@/pages/layout-desktop/AvailabilityCalendar.vue')
    },
];