import axios from 'axios'
import { urlPath } from '@/rest-config'

export const checkAuth = async (token) => {
    return axios.request({
        method: 'POST',
        url: urlPath('/api/acc/token'),
        headers: {
            'Accept': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${token}`
        }
    }).then((resp) => {
        if (resp.data.ok) localStorage.setItem('jwt', resp.data.token)
        return resp;
    }).catch(() => {
        localStorage.setItem('jwt', '')
    })
}

export const checkSignup = async (token) => {
    return axios.request({
        method: 'POST',
        url: urlPath('/api/signup/verify'),
        data: {
            token: token
        }
    })
}

export const signup = async (token, password) => {
    return axios.request({
        method: 'POST',
        url: urlPath('/api/signup/signup'),
        data: {
            token: token,
            password: password
        }
    }).then(resp => {
        if (resp.data.ok) localStorage.setItem('jwt', resp.data.token)
        return resp
    }).catch(() => {
        localStorage.setItem('jwt', '')
    })
}

export const login = async (user, pwd) => {
    return axios.request({
        method: 'POST',
        url: urlPath('/api/signup/login'),
        headers: {
            'Accept': 'application/json;charset=UTF-8'        
        },
        data: {
            name: user,
            password: pwd
        }
    }).then((resp) => {
        if (resp.data.ok) localStorage.setItem('jwt', resp.data.token)
        return resp
    }).catch(() => {
        localStorage.setItem('jwt', '')
    })
}

