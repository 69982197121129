import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Vuetify from 'vuetify/lib'
import theme from './plugins/theme'
import { routesDesktopAuth, routesMobileAuth, routesUnauthenticated } from './router'
import store from './store'
import { checkAuth } from './auth'

const initApp = (go, mobileDetected) => {
  console.log('initApp(go, mobile)', go, mobileDetected)

  Vue.use(VueRouter)
  Vue.use(Vuetify)
  const vuetify = new Vuetify({
    theme: {
      dark: false,
      defaultTheme: 'light',
      themes: {
        light: theme
      }
    },
    icons: {
      iconfont: 'mdi'
    }
  })

  const routes = []
  const jwtRetrieved = localStorage.getItem('jwt')
  if (jwtRetrieved && jwtRetrieved.length > 0) {
    console.log('initApp-jwtRetrieved', store.state.layout)
    store.commit('jwt', jwtRetrieved)

    checkAuth(jwtRetrieved).then(resp => {
      if (resp.data.ok) {
        store.commit('user', resp.data.user)
        store.commit('today', resp.data.today)
      }
      // return resp.data.ok ? resp.data.layout : 'blank'
      if (resp.data.ok) {
          return (store.state.layout) ? store.state.layout : resp.data.layout
      }
      else {
          return mobileDetected ? 'mobile' : 'blank'
      }
    }).catch(() => {
      store.commit('layout', 'blank')
      return 'blank'
    }).then(suggested => {
      console.log('initApp-layout suggested', suggested)
      store.commit('layout', suggested)

      if (suggested == 'blank') {
          routes.push(routesUnauthenticated)
          console.log('initApp-routes unauthenticated served')
      }
      else {
          console.log('initApp-suggested match?', suggested, store.state.layout)
          
          console.log('initApp-choose what is referred to', suggested, store.state.layout)
          if (store.state.layout == 'mobile')
              routes.push(routesMobileAuth)
          else
              routes.push(routesDesktopAuth)
        
          return store.state.layout
      }})
      .then((layout) => {
          console.log('initApp-layout ready:', layout)
          store.commit('layout', layout)

          const router = new VueRouter({
            mode: 'history',
            routes: routes[0]
          })
  
          new Vue({
            vuetify,
            router,
            store,
            render: h => h(App)
          }).$mount('#app')
          if (go) router.push(go)
    })
  }
  else {
    console.log('initApp-other: no jwt retrieved yet')
    store.commit('layout', 'blank')

    const router = new VueRouter({
      mode: 'history',
      routes: routesUnauthenticated
    })

    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')

    if (go) router.push(go)
  }
}

Vue.config.productionTip = false
initApp()

export default initApp