export default {
    background: '#ffffff',
    surface: '#e9f2f4',
    'surface-bright': '#ffffff',
    white: '#ffffff',

    primary: '#2e8fe0',
    'primary-darken': '#1162a5',
    'primary-lighten': '#d6ecff',

    secondary: '#008754',

    error: '#c4122e',
    info: '#1162a5',
    success: '#008754',
    warning: '#222c45',

    neutral: '#95a7b1',
    'neutral-darken': '#222c45',
    'neutral-lighten': '#e9f2f4',

    positive: '#008754',
    'positive-lighten': '#90db98',

    negative: '#c4122e',
    'negative-lighten': '#ff988a',
}