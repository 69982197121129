<template>
    <v-app>
        <v-main>
            <router-view></router-view>
        </v-main>
        <v-bottom-navigation class="neutral-lighten"
            app
        >
            <v-btn v-bind:key="item.key" v-for="item in navigation" 
                height="100%"
                class="neutral-lighten"
                v-on:click="navigate(item)"
            >
                <span>{{item.label}}</span>
            </v-btn>
        </v-bottom-navigation>
    </v-app>
</template>

<script>
export default {
    data: () => ({
        navigation: [
            { 
                path: '/parking',
                label: "My Parking",
            }, 
            {
                path: '/reservation',
                label: "Make Reservation",
            },
            {
                path: '/calendar',
                label: "Calendar",
            }
        ]
    }),
    methods: {
        navigate(section) {
            this.$router.push(section.path).catch((err) => { console.log(err) })
        }
    },
    computed: {
        today() {
            return this.$store.state.today
        },
        user() {
            return this.$store.state.user
        }
    }
}
</script>

<style></style>